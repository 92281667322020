<template>
  <div class="mb-3">
    <label
      v-if="label"
      class="form-label"
    >
      {{ label || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <VueDatePicker
      v-model="value"
      :class="{ 'is-invalid': hasErrors }"
      :enableTimePicker="false"
      :format="formatter"
      :maxTime="{ hours: 0, minutes: 0, seconds: 0 }"
      :minTime="{ hours: 0, minutes: 0, seconds: 0 }"
      :readonly="readonly"
      autoApply
      class="form-control date-picker-component"
      utc="preserve"
      @blur="e => $emit('blur', e)"
    />
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Tooltip } from 'bootstrap';

export default {
  components: {
    VueDatePicker
  },
  props: [ 'modelValue', 'label', 'description', 'readonly', 'errors', 'formatter' ],
  emits: [ 'update:modelValue', 'blur' ],
  computed: {
    hasErrors() {
      return this.errors?.length > 0;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  }
};
</script>
<style>
.date-picker-component {
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.date-picker-component input {
  border: 0 !important;
}
</style>
